import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';

import Button from 'gatsby-theme-gaviscon/src/components/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import { ControledAccordionProps } from './model';
import './ControledAccordion.scss';

const ControledAccordion: FC<ControledAccordionProps> = ({ items, selectedIndex }) => {
  const index = selectedIndex
    ? selectedIndex >= items.length
      ? items.length - 1
      : selectedIndex - 1
    : null;

  return (
    <Accordion
      className="gs-accordion"
      transitionDuration="300"
      {...(index && { selectedIndex: index })}
    >
      {items?.length
        ? items.map(({ properties: { answer, question } }) => (
          <div
            className="gs-accordion__item"
            key={question}
            data-header={
              <h3 className="gs-accordion__question-seo">
                <Button variant="icon" classes="gs-accordion__question">
                  <IconCustom icon="chevron-down" />
                  {question}
                </Button>
              </h3>
            }
          >
            <DangerouslySetInnerHtml html={answer} />
          </div>
        ))
        : null}
    </Accordion>
  );
};

export default ControledAccordion;
